import { render, staticRenderFns } from "./ProductsComponent.vue?vue&type=template&id=ebd233f8"
import script from "./ProductsComponent.vue?vue&type=script&lang=js"
export * from "./ProductsComponent.vue?vue&type=script&lang=js"
import style0 from "./ProductsComponent.vue?vue&type=style&index=0&id=ebd233f8&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports