<template>
  <div v-if="title" class="content-header">
    <h2 class="content-header__title">{{ title }}</h2>
    <LinkMoreComponent
      class="content-header__link"
      :title="linkTitle"
      :route="route || { name: 'catalog' }"
    />
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import LinkMoreComponent from "components/LinkMoreComponent.vue";
export default {
  name: "ContentHeaderComponent",
  components: { LinkMoreComponent, IconComponent },
  props: {
    title: String,
    route: {
      type: Object,
      default() {
        return { name: "" };
      },
    },
    linkTitle: {
      type: String,
      default: "Смотреть все",
    },
    hideMore: Boolean,
  },
};
</script>

<style lang="stylus">
.content-header {
  margin-bottom var(--gap)
  display flex
  flex-wrap wrap
  align-items center
  justify-content space-between
  gap: 5px 30px

  &__title {
    margin 0
  }
}
</style>
