<template>
  <div class="banners">
    <SliderComponent
      class="banners__slider"
      :items="banners"
      v-slot="{ item }"
      :slider-options="sliderOptions"
    >
      <div class="banners__slider-img">
        <div class="ratio-container">
          <ImgComponent :img="item.img" :lazy="false" />
        </div>
      </div>
    </SliderComponent>
    <DoctorReferralComponent />
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import DoctorReferralComponent from "components/DoctorReferralComponent.vue";

export default {
  name: "BannersComponent",
  props: {
    banners: Array,
  },
  data() {
    return {
      sliderOptions: {
        slidesPerView: 1,
        pagination: false,
        clickable: false,
        preloadImages: true,
        loop: true,
      },
    };
  },
  components: { DoctorReferralComponent, SliderComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.banners {
  display grid
  grid-template-columns 920fr 440fr
  grid-gap var(--gap)
  +below(1100px) {
    grid-template-columns 1fr
  }
  +below(640px) {
    grid-gap 20px
  }

  &__slider {
    position relative
    border-radius var(--big-radius)
    overflow hidden

    .slider-arrows {
      position absolute
      left 40px
      bottom 40px
      +below(540px) {
        left 15px
        bottom 15px
        gap: 5px
      }

      &__item {
        .icon svg path {
          fill var(--white)
        }
      }
    }

    &-img {
      ratio(920, 500)
    }
  }
}
</style>
